import { Button, Popconfirm, Table, Tag } from "antd";
import React, { useMemo } from "react";
import { styled } from "styled-components";
import {
  EditOutlined,
  DeleteOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import Utils from "utils";
import { permitKey, PermitValue } from "constants";
import { deleteBanHang } from "store/slices/sale/banhangSlice";
import { DOCUMENT_TYPE_ID } from "constants";
import { deleteSignedDocument } from "store/slices/signatureProcessSlice";

export default function TableBanLeList({
  setBanHangId,
  setMaKkxuat,
  setNgayxuat,
  setMadoituong,
  banHangList,
  setVisibleModal,
  reloadData,
  permitList = [],
  setIsPrinter,
  setPrintData,
}) {
  const dispatch = useDispatch();
  const allowLock = Utils.checkPermitValue(
    PermitValue.khoa,
    permitList,
    permitKey.crm_hospital
  );
  const allowEdit = Utils.checkPermitValue(
    PermitValue.sua,
    permitList,
    permitKey.crm_hospital
  );
  const allowDelete = Utils.checkPermitValue(
    PermitValue.xoa,
    permitList,
    permitKey.crm_hospital
  );

  const tableColumns = useMemo(
    () => [
      {
        title: "STT",
        width: 50,
        align: "center",
        render: (_, __, index) => index + 1,
      },
      {
        title: "Số phiếu",
        width: 90,
        dataIndex: "sophieu",
      },
      {
        title: "Ngày",
        width: 120,
        dataIndex: "ngay",
        render: (value) => Utils.formatDate(value),
      },
      {
        title: "Kho xuất",
        width: 160,
        dataIndex: "tenkk",
      },
      {
        title: "Khách hàng",
        width: 180,
        dataIndex: "hoten",
      },
      {
        title: "Trạng thái",
        width: 80,
        dataIndex: "daduyet",
        align: "center",
        render: (value) => (
          <Tag color={value ? "green" : "red"}>
            {value ? "Đã duyệt" : "Chưa duyệt"}
          </Tag>
        ),
      },
      {
        width: 150,
        fixed: "right",
        align: "center",
        render: (_, record) => (
          <>
            <Button
              disabled={!allowEdit}
              title={"Sửa thông tin phiếu"}
              onClick={() => {
                setVisibleModal(true);
                setBanHangId(record.mabanhang);
                setMaKkxuat(record.makk);
                setNgayxuat(record.ngay);
                setMadoituong(record.madoituong);
              }}
              className="mr-2"
              icon={<EditOutlined />}
              shape="circle"
            />
            <Popconfirm
              disabled={!allowDelete}
              title="Bạn muốn xóa phiếu?"
              placement="topLeft"
              onConfirm={() => {
                dispatch(
                  deleteBanHang({
                    id: record.mabanhang,
                    onSuccess: () => {
                      const item = {
                        documentId: record.idphieu,
                        documentTypeId: DOCUMENT_TYPE_ID.BanLe,
                      };
                      dispatch(deleteSignedDocument(item));
                      reloadData();
                    },
                  })
                );
              }}
            >
              <Button
                disabled={!allowDelete}
                title={
                  allowDelete ? "Xóa phiếu" : "Bạn không có quyền thao tác"
                }
                className="mr-2"
                icon={<DeleteOutlined type="primary" />}
                shape="circle"
              />
            </Popconfirm>

            <Button
              title={"In phiếu"}
              className="mr-2"
              onClick={() => {
                setIsPrinter(true);
                setPrintData({
                  idphieu: record.idphieu,
                  mabanhang: record.mabanhang,
                  sophieu: record.sophieu,
                });
              }}
              icon={<PrinterOutlined type="primary" />}
              shape="circle"
            />
            {/* <Button
              title={"Trả lại hàng hóa"}
              className="mr-2"
              icon={<UndoOutlined type="primary" />}
              shape="circle"
            /> */}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [banHangList]
  );

  return (
    <CustomTable
      columns={tableColumns}
      dataSource={banHangList}
      scroll={{ x: "max-content" }}
      rowKey={(item) => item?.id}
    />
  );
}

const CustomTable = styled(Table)`
  .avatar-status:hover {
    text-decoration: underline;
  }
`;
