import { Timeline } from "antd";
import React, { memo } from "react";
import { EditOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import Utils from "utils";

const iconSvg = {
  update: <EditOutlined />,
  add: <PlusOutlined />,
  delete: <DeleteOutlined />,
};

const colorSvg = {
  update: "#EF5816",
  add: "#219737",
  delete: "red",
};

const MediTimeline = ({ data, customHeader }) => {
  return (
    <>
      {customHeader}
      <Timeline>
        {data.map((item) => (
          <Timeline.Item
            dot={iconSvg[item.thaoTac]}
            color={colorSvg[item.thaoTac]}
          >
            <div style={{ color: "#616161", fontSize: 12 }}>
              {Utils.formatDate(item.createdAt)}
            </div>
            <div>
              <span
                style={{ fontWeight: "bold", fontSize: 14, color: "#181918" }}
              >
                {`${item.nguoiSua} `}
              </span>
              <span style={{ fontSize: 14, color: "#333537" }}>
                {item.noiDung}
              </span>
            </div>
          </Timeline.Item>
        ))}
      </Timeline>
    </>
  );
};

export default memo(MediTimeline);
