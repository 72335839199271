import {
  Button,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Table,
  Tooltip,
} from "antd";

import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { styled } from "styled-components";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import Utils from "utils";
import {
  addNewNhapKhoCT,
  getNhapKhoCTByManx,
  setNhapKhoCTList,
  updateNhapKhoCT,
  deleteNhapKhoCT,
} from "store/slices/sale/nhapkhoSlice";

import { initNhapKhoCt } from "views/app-views/sale/managements/QuanLyKho/NhapKho";
import { isEmpty } from "lodash";
import { substr } from "stylis";
import moment from "moment";
import { dateFormatList } from "constants";
import { guidEmpty } from "constants";

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    if (record["action"] !== "initial" && record["action"] !== "update") {
      return;
    }

    if (
      record["action"] !== "initial" &&
      (dataIndex === "tenhh" || dataIndex === "maview")
    ) {
      return;
    }

    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });

    if (dataIndex === "handung") {
      if (record["action"] !== "initial" && record["handung"] !== null) {
        form.setFieldValue([dataIndex], moment(record["handung"]));
      } else {
        form.setFieldValue([dataIndex], null);
      }
    }
  };
  const onChangeTenhh = (value) => {
    if (value === null) return;
    const data = restProps.dmThuocVatTuList.find((i) => i.mahh === value);
    form.setFieldValue([dataIndex], data.tenhh);
    handleSave(
      {
        ...record,
        mahh: data.mahh,
        maview: data.maview,
        tenhh: data.tenhh,
        tendonvitinh: data.tenDonViTinh,
        sodangky: data.sodangky,
        dongia: data.dongia,
      },
      dataIndex
    );
  };
  const onChangeMaview = (value) => {
    if (value === null) return;
    const data = restProps.dmThuocVatTuList.find((i) => i.mahh === value);
    form.setFieldValue([dataIndex], data.maview);
    handleSave(
      {
        ...record,
        mahh: data.mahh,
        maview: data.maview,
        tenhh: data.tenhh,
        tendonvitinh: data.tenDonViTinh,
        sodangky: data.sodangky,
        dongia: data.dongia,
      },
      dataIndex
    );
  };
  const onChangeHanDung = (date, dates) => {
    if (date === null) {
      restProps.setEditHSD(false);
    } else {
      restProps.setEditHSD(true);
    }
  };
  const onChangeSoLuong = (value) => {
    if (value === null) return;
    handleSave(
      {
        ...record,
        thanhtien: record.dongia * value,
      },
      dataIndex
    );
  };
  const onChangeThanhTien = (value) => {
    if (value === null) return;
    if (record.soluong === null || record.soluong <= 0) return;
    handleSave(
      {
        ...record,
        dongia: value / record.soluong,
      },
      dataIndex
    );
  };

  const onChangeDonGia = (value) => {
    if (value === null) return;
    handleSave(
      {
        ...record,
        thanhtien: record.soluong * value,
      },
      dataIndex
    );
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values }, dataIndex);
    } catch (errInfo) {}
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        {dataIndex === "handung" ? (
          <DatePicker
            format={dateFormatList}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            placeholder="Hạn dùng"
            onChange={(date, dates) => onChangeHanDung(date, dates)}
          />
        ) : dataIndex === "soluong" ? (
          <InputNumber
            style={{ width: "100%" }}
            step={1}
            formatter={(value) => Utils.formatterNumber(value)}
            parser={(value) => parserNumber(value)}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            onChange={(value) => onChangeSoLuong(value)}
          />
        ) : dataIndex === "thanhtien" ? (
          <InputNumber
            style={{ width: "100%" }}
            step={1}
            formatter={(value) => Utils.formatterNumber(value)}
            parser={(value) => parserNumber(value)}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            onChange={(value) => onChangeThanhTien(value)}
          />
        ) : dataIndex === "dongia" ? (
          <InputNumber
            style={{ width: "100%" }}
            step={100}
            formatter={(value) => Utils.formatterNumber(value)}
            parser={(value) => parserNumber(value)}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            onChange={(value) => onChangeDonGia(value)}
          />
        ) : dataIndex === "tenhh" ? (
          <Select
            style={{ width: "100%" }}
            placeholder="Chọn thuốc vật tư"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            onChange={(value) => onChangeTenhh(value)}
            dropdownStyle={{
              maxHeight: 300,
              overflow: "auto",
              minWidth: 700,
            }}
          >
            {restProps.dmThuocVatTuList.map((x) => (
              <Select.Option label={x.tenhh} value={x.mahh} key={x.mahh}>
                <div style={{ display: "flex" }}>
                  <Image
                    src="https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg"
                    style={{ width: 50, height: 50 }}
                  />
                  <table style={{ borderCollapse: "collapse", width: "650px" }}>
                    <tr style={{ border: "1px solid #dddddd", width: "650px" }}>
                      <th
                        style={{
                          width: "100px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        Mã số
                      </th>
                      <th
                        style={{
                          width: "350px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        Tên thuốc vật tư
                      </th>
                      <th
                        style={{
                          width: "60px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        ĐVT
                      </th>
                      <th
                        style={{
                          width: "80px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        Đơn giá
                      </th>
                    </tr>
                    <tr style={{ border: "1px solid #dddddd", width: "650px" }}>
                      <td
                        style={{
                          width: "100px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        <Tooltip title={x.maview}>
                          {substr(x.maview, 0, 12) +
                            (x.maview.length > 12 ? "..." : "")}
                        </Tooltip>
                      </td>
                      <td
                        style={{
                          width: "350px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        <Tooltip title={x.tenhh}>
                          {substr(x.tenhh, 0, 50) +
                            (x.tenhh.length > 50 ? "..." : "")}
                        </Tooltip>
                      </td>
                      <td
                        style={{
                          width: "60px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        {x.tenDonViTinh}
                      </td>
                      <td
                        style={{
                          width: "80px",
                          border: "1px solid #dddddd",
                          textAlign: "right",
                        }}
                      >
                        {Utils.formatterNumber(x.dongia)}
                      </td>
                    </tr>
                  </table>
                </div>
              </Select.Option>
            ))}
          </Select>
        ) : dataIndex === "maview" ? (
          <Select
            style={{ width: "100%" }}
            placeholder="Chọn thuốc vật tư"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            onChange={(value) => onChangeMaview(value)}
            dropdownStyle={{
              maxHeight: 300,
              overflow: "auto",
              minWidth: 700,
            }}
          >
            {restProps.dmThuocVatTuList.map((x) => (
              <Select.Option label={x.maview} value={x.mahh} key={x.mahh}>
                <div style={{ display: "flex" }}>
                  <Image
                    src="https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg"
                    style={{ width: 50, height: 50 }}
                  />
                  <table style={{ borderCollapse: "collapse", width: "650px" }}>
                    <tr style={{ border: "1px solid #dddddd", width: "650px" }}>
                      <th
                        style={{
                          width: "100px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        Mã số
                      </th>
                      <th
                        style={{
                          width: "350px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        Tên thuốc vật tư
                      </th>
                      <th
                        style={{
                          width: "60px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        ĐVT
                      </th>
                      <th
                        style={{
                          width: "80px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        Đơn giá
                      </th>
                    </tr>
                    <tr style={{ border: "1px solid #dddddd", width: "650px" }}>
                      <td
                        style={{
                          width: "100px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        <Tooltip title={x.maview}>
                          {substr(x.maview, 0, 12) +
                            (x.maview.length > 12 ? "..." : "")}
                        </Tooltip>
                      </td>
                      <td
                        style={{
                          width: "350px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        <Tooltip title={x.tenhh}>
                          {substr(x.tenhh, 0, 50) +
                            (x.tenhh.length > 50 ? "..." : "")}
                        </Tooltip>
                      </td>
                      <td
                        style={{
                          width: "60px",
                          border: "1px solid #dddddd",
                        }}
                      >
                        {x.tenDonViTinh}
                      </td>
                      <td
                        style={{
                          width: "80px",
                          border: "1px solid #dddddd",
                          textAlign: "right",
                        }}
                      >
                        {Utils.formatterNumber(x.dongia)}
                      </td>
                    </tr>
                  </table>
                </div>
              </Select.Option>
            ))}
          </Select>
        ) : (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 10,
          height: 20,
          width: "100%",
        }}
        onClick={toggleEdit}
      >
        {restProps.isDate
          ? record.handung
            ? dayjs(record.handung).format("DD/MM/YYYY")
            : null
          : children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export const parserNumber = (val) => {
  if (!val) return 0;
  return Number.parseFloat(
    val.replace(/\$\s?|(\.*)/g, "").replace(/(,{1})/g, ".")
  ).toFixed(5);
};
export default function TablenhapKhoCTList({
  setNhapKhoId,
  nhapKhoCTList,
  setVisibleModal,
  visibleModal,
  reloadData,
  dmThuocVatTuList,
  nhapKhoId,
  permitList = [],
}) {
  const dispatch = useDispatch();
  const [editHSD, setEditHSD] = useState(false);
  const onClickCancelAction = (record, index) => {
    setEditHSD(false);
    const newData = nhapKhoCTList.slice(0);
    const indexX = newData.findIndex((item) => record.manxct === item.manxct);
    newData[indexX] = { ...newData[indexX], action: "" };
    dispatch(setNhapKhoCTList(newData));
  };

  const onClickAction = (record, index) => {
    const newData = nhapKhoCTList.slice(0);

    const payload = {
      manxct: record.manxct,
      mahh: record.mahh,
      tenhh: record.tenhh,
      maview: record.maview,
      losx: record.losx,
      handungtext:
        record.action === "initial" && (record.handung === null || !editHSD)
          ? ""
          : Utils.formatDate(record.handung, true),
      dongia: record.dongia,
      thanhtien: record.thanhtien,
      soluong: record.soluong,
      manx: nhapKhoId,
      onSuccess: () => {
        dispatch(
          getNhapKhoCTByManx({
            manx: nhapKhoId,
            isNewCt: record.action === "initial",
          })
        );
      },
    };
    setEditHSD(false);

    if (isEmpty(payload.tenhh)) {
      return;
    }
    if (record.action === initNhapKhoCt.action) {
      dispatch(addNewNhapKhoCT(payload));
    } else {
      if (record.manxct !== null && record.action !== "update") {
        const indexX = newData.findIndex(
          (item) => record.manxct === item.manxct
        );
        newData[indexX] = { ...newData[indexX], action: "update" };
        dispatch(setNhapKhoCTList(newData));
      } else {
        dispatch(updateNhapKhoCT(payload));
      }
    }
  };
  const tableColumns = useMemo(
    () => [
      {
        title: "STT",
        width: 40,
        align: "center",
        dataIndex: "thuTu",
      },
      {
        title: "Mã số",
        width: 80,
        dataIndex: "maview",
        editable: true,
        render: (txt, record) => (
          <Tooltip title={record.maview}>
            <div>
              {!isEmpty(record.maview) ? (
                record.maview.substr(0, 15) +
                (record.maview?.length > 15 ? "..." : "")
              ) : (
                <i
                  style={{
                    fontSize: "16px",
                    color: "blue",
                    paddingLeft: "20px",
                  }}
                >
                  ...
                </i>
              )}
            </div>
          </Tooltip>
        ),
      },
      {
        title: "Tên thuốc, vật tư",
        width: 180,
        dataIndex: "tenhh",
        editable: true,
        render: (txt, record) => (
          <Tooltip title={record.tenhh}>
            <div>
              {!isEmpty(record.tenhh) ? (
                record.tenhh.substr(0, 45) +
                (record.tenhh?.length > 45 ? "..." : "")
              ) : (
                <i
                  style={{
                    fontSize: "16px",
                    color: "blue",
                    paddingLeft: "20px",
                  }}
                >
                  Chọn thuốc, vật tư...
                </i>
              )}
            </div>
          </Tooltip>
        ),
      },
      {
        title: "ĐVT",
        width: 50,
        dataIndex: "tendonvitinh",
      },
      {
        title: "Lô SX",
        width: 70,
        dataIndex: "losx",
        editable: true,
      },
      {
        title: "HSD",
        width: 90,
        dataIndex: "handung",
        isDate: true,
        editable: true,
        align: "center",
      },
      {
        title: "Số lượng",
        width: 80,
        dataIndex: "soluong",
        align: "right",
        editable: true,
        isMoney: true,
        render: (value) => (value ? Utils.formatterNumber(value) : 0),
      },
      {
        title: "Đơn giá",
        width: 80,
        dataIndex: "dongia",
        align: "right",
        editable: true,
        isMoney: true,
        render: (value) => (value ? Utils.formatterNumber(value) : 0),
      },
      {
        title: "Thành tiền",
        width: 90,
        dataIndex: "thanhtien",
        align: "right",
        editable: true,
        isMoney: true,
        render: (value) => (value ? Utils.formatterNumber(value) : 0),
      },
      {
        fixed: "right",
        width: 95,
        align: "center",
        dataIndex: "action",
        render: (_, record, index) => (
          <>
            {record.action === "initial" ? (
              <Button
                disabled={
                  !record.tenhh || nhapKhoId === null || nhapKhoId === 0
                }
                title={"Thêm chi tiết"}
                onClick={() => onClickAction(record, index)}
                className="mr-2"
                icon={<PlusOutlined />}
                shape="circle"
              />
            ) : record.action === "update" ? (
              <>
                <Button
                  title="Lưu chi tiết"
                  disabled={
                    nhapKhoCTList.filter((x) => x.action === "update").length >
                      0 &&
                    record.manxct !==
                      nhapKhoCTList.filter((x) => x.action === "update")[0]
                        ?.manxct
                  }
                  onClick={() => onClickAction(record, index)}
                  className="mr-2"
                  icon={<SaveOutlined />}
                  shape="circle"
                />
                <Button
                  title="Hủy"
                  disabled={
                    nhapKhoCTList.filter((x) => x.action === "update").length >
                      0 &&
                    record.manxct !==
                      nhapKhoCTList.filter((x) => x.action === "update")[0]
                        ?.manxct
                  }
                  onClick={() => onClickCancelAction(record, index)}
                  className="mr-2"
                  icon={<CloseOutlined />}
                  shape="circle"
                />
              </>
            ) : (
              <>
                <Button
                  title="Sửa chi tiết"
                  disabled={
                    nhapKhoCTList.filter((x) => x.action === "update").length >
                      0 &&
                    record.manxct !==
                      nhapKhoCTList.filter((x) => x.action === "update")[0]
                        ?.manxct
                  }
                  onClick={() => onClickAction(record, index)}
                  className="mr-2"
                  icon={<EditOutlined />}
                  shape="circle"
                />
                <Popconfirm
                  title="Bạn có muốn xóa chi tiết?"
                  placement="topLeft"
                  onConfirm={() => {
                    dispatch(
                      deleteNhapKhoCT({
                        id: record.manxct,
                        onSuccess: () => {
                          dispatch(
                            getNhapKhoCTByManx({
                              manx: nhapKhoId,
                              isNewCt: false,
                            })
                          );
                        },
                      })
                    );
                  }}
                >
                  <Button
                    title={"Xóa chi tiết"}
                    disabled={
                      nhapKhoCTList.filter((x) => x.action === "update")
                        .length > 0 &&
                      record.manxct !==
                        nhapKhoCTList.filter((x) => x.action === "update")[0]
                          ?.manxct
                    }
                    className="mr-2"
                    icon={<DeleteOutlined type="primary" />}
                    shape="circle"
                  />
                </Popconfirm>
              </>
            )}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nhapKhoCTList]
  );

  const handleSave = (row) => {
    const newData = nhapKhoCTList.slice(0);
    const index = newData.findIndex((item) => row.manxct === item.manxct);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    dispatch(setNhapKhoCTList(newData));
  };

  const columns = tableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
        isDate: col.isDate,
        isMoney: col.isMoney,
        dmThuocVatTuList: dmThuocVatTuList,
        nhapKhoCTList: nhapKhoCTList,
        setEditHSD: setEditHSD,
      }),
    };
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  return (
    <CustomTable
      components={components}
      columns={columns}
      dataSource={nhapKhoCTList}
      rowKey={(_, index) => index}
      bordered
      style={{ padding: "0px" }}
      //fixed={true}
      pagination={false}
      scroll={{ x: 950, y: "calc(100vh - 520px)" }}
      footer={(nhapKhoCTList) => (
        <div>
          <div>
            Số khoản:
            <b style={{ color: "green", paddingLeft: "5px" }}>
              {Utils.formatterNumber(
                nhapKhoCTList.filter((x) => x.manxct).length
              )}
            </b>
          </div>
          <div>
            Tổng tiền:
            <b style={{ color: "red", paddingLeft: "5px" }}>
              {Utils.formatterNumber(
                nhapKhoCTList
                  .filter((x) => x.manxct)
                  .reduce((prev, current) => prev + current?.thanhtien || 0, 0)
              )}
            </b>
            <span style={{ margin: "10px" }} />
            Tổng tiền sau thuế:
            <b style={{ color: "red", paddingLeft: "5px" }}>
              {Utils.formatterNumber(
                nhapKhoCTList
                  .filter((x) => x.manxct)
                  .reduce(
                    (prev, current) => prev + current?.thanhtienthue || 0,
                    0
                  )
              )}
            </b>
          </div>
        </div>
      )}
    />
  );
}

const CustomTable = styled(Table)`
  // .ant-table-tbody > tr > td {
  //   padding: 2px;
  // }
`;
