export const guidEmpty = "00000000-0000-0000-0000-000000000000";

export const FILTER_TIME_ID = {
  Yesterday: 1,
  Today: 2,
  ThisWeek: 3,
  ThisMonth: 4,
  LastMonth: 5,
  ThisYear: 6,
};

export const FILTER_TIME_BY_BRANCH = [
  { value: FILTER_TIME_ID.Yesterday, label: "Hôm qua" },
  { value: FILTER_TIME_ID.Today, label: "Hôm nay" },
  { value: FILTER_TIME_ID.ThisWeek, label: "Tuần này" },
  { value: FILTER_TIME_ID.ThisMonth, label: "Tháng này" },
  { value: FILTER_TIME_ID.LastMonth, label: "Tháng trước" },
  { value: FILTER_TIME_ID.ThisYear, label: "Năm nay" },
];

export const FILTER_TIME_BY_ALL_BRANCH = [
  { value: FILTER_TIME_ID.ThisWeek, label: "Tuần này" },
  { value: FILTER_TIME_ID.ThisMonth, label: "Tháng này" },
  { value: FILTER_TIME_ID.ThisYear, label: "Năm này" },
];

export const STATUS_RATING = [
  { value: 1, label: "Rất Kém", color: "#ff6b72" },
  { value: 2, label: "Cần cải thiện", color: "#fa8c16" },
  { value: 3, label: "Bình thường", color: "#ffc107" },
  { value: 4, label: "Hài lòng", color: "#04d182" },
  { value: 5, label: "Rất hài lòng", color: "#3e82f7" },
];

export const GENDER = [
  { value: 1, label: "Nam" },
  { value: 2, label: "Nữ" },
  { value: 3, label: "Khác" },
];

export const dateFormatList = [
  "DD/MM/YYYY",
  "DD/MM/YY",
  "DD-MM-YYYY",
  "DD-MM-YY",
  "DDMMYYYY",
  "DDMMYY",
];

export const dateTimeFormatList = [
  "DD/MM/YYYY HH:mm",
  "DD/MM/YY HH:mm",
  "DD-MM-YYYY HH:mm",
  "DD-MM-YY HH:mm",
];

export const EMaLoaiKK = [
  { value: 1, label: "Kho" },
  { value: 2, label: "Khoa" },
  { value: 3, label: "Hành chính" },
  { value: 4, label: "Tủ trực" },
  { value: 5, label: "Nhà cung cấp" },
];

export const EThietLapKK = [
  { value: -1, label: "Không sử dụng" },
  { value: 0, label: "Mặc định" },
  { value: 1, label: "Khoa khám bệnh" },
  { value: 2, label: "Khoa điều trị" },
  { value: 3, label: "Nhà thuốc" },
  { value: 4, label: "kho tồn cũ" },
];

export const QuyenThaoTac = [
  { id: 1, name: "Thêm" },
  { id: 2, name: "Sửa" },
  { id: 3, name: "Xóa" },
  { id: 4, name: "In" },
  { id: 5, name: "Sao chép" },
];

export const PermitArr = [
  { name: "Them", value: 1 },
  { name: "Sua", value: 2 },
  { name: "Xoa", value: 4 },
  { name: "In", value: 8 },
  { name: "Xem", value: 16 },
  { name: "ChoPhep", value: 32 },
  { name: "Khoa", value: 64 },
];

export const PersonnelForm = [
  { value: 1, label: "Nhân viên công ty/tập đoàn" },
  { value: 2, label: "Nhân viên để tên" },
  { value: 3, label: "Thuê chứng chỉ" },
];
export const HoSoDeTenArr = [
  { value: 1, label: "Có HS để tên" },
  { value: 2, label: "Không có HS để tên (làm công BHXH)" },
];
export const SalaryBaseData = [
  { value: 1, label: "Lương cơ bản (theo lương bảo hiểm)" },
  { value: 2, label: "Lương hợp đồng" },
];

export const PermitValue = {
  them: 1,
  sua: 2,
  xoa: 4,
  in: 8,
  xem: 16,
  chophep: 32,
  khoa: 64,
};
export const permitKey = {
  hrm_category: 1201,
  hrm_employee: 1202,
  hrm_department_setting: 1203,
  hrm_bhyt_dsnv: 1204,
  hrm_hoso: 1205,
  hrm_thietlapbangcong: 1206,
  hrm_danhsachbangcong: 1207,
  hrm_noibo: 1208,
  hrm_quanlycong: 1209,
  hrm_quanlynghi: 1210,
  // Luong area
  hrm_thietLapLuong: 1250,
  hrm_quanlyluong: 1251,
  hrm_duyetbangluong: 1252,
  // end Luong Area
  hrm_listContract: 1231,
  hrm_settingContract: 1230,
  hrm_listTraining: 1241,
  hrm_manageTraining: 1242,
  hrm_settingTraining: 1240,
  hrm_manageOvertime: 1243,
  hrm_manageMealAllowance: 1244,
  // Tuyen dung area
  hrm_tuyendung: 1290,
  hrm_duyettuyendung: 1291,
  hrm_thietlaptuyendung: 1292,

  // End tuyen dung area
  crm_report_qos: 1301, // bao cao hoat dong kham chua benh
  crm_service: 1302, // bao cao chat luong dich vu
  crm_hospital: 1303,
  crm_doctor: 1304,
  crm_kcb: 1305,
  crm_service_medical: 1306,
  crm_posts: 1307,
  crm_notification: 1308,
  crm_thietlap: 1309,
  crm_comment: 1310,
  crm_danhmucdichvutainha: 1311,
  crm_dichvutainha: 1312,
  crm_lichkhamlai: 1313,
  crm_thietlapdmksk: 1314,
  crm_khamsuckhoedoan: 1315,
  crm_thanhtoankskdoan: 1316,
  crm_congtacvien: 1317,
  crm_doankhamksk: 1318,
  crm_donhangksk: 1319,

  identity_userBranch: 1101,
  identity_scope: 1102,
  identity_role: 1103,
  identity_user_role: 1104,
  project_quanlycongvanvanban: 1601,
  project_thietlap: 1602,
  // Quan ly Quy che
  project_regulationManage: 1604,
  project_quanLyVanBan: 1605,
  project_quanLyHoSo: 1606,
  project_regulationSetting: 1603,
  project_quanlyduan: 1690,
  project_duyetduan: 1691,
  project_quanlycongviec: 1692,
  //common
  common_signatureProcess: 1401,

  //sale
  sale_dexuatmuasam: 1501,
  pharma_quanlykho: 1502,
  pharma_banhang: 1503,
  pharma_common: 1504,
  pharma_thanhtoan: 1505,
  sale_tonghopdexuatmuasam: 1506,
};

export const SystemButtonSource = {
  addBtn: "addBtn",
  updateBtn: "updateBtn",
  deleteBtn: "deleteBtn",
  downloadBtn: "downloadBtn",
  refreshBtn: "refreshBtn",
  importBtn: "importBtn",
  import1Btn: "import1Btn",
  exportBtn: "exportBtn",
  export1Btn: "export1Btn",
  lockBtn: "lockBtn",
  unLockBtn: "unLockBtn",
  calculatorBtn: "calculatorBtn",
};

export const TRAINING_STATUS_OPTION = [
  {
    id: 0,
    name: "Chưa bắt đầu",
    color: "gray",
  },
  {
    id: 1,
    name: "Đang đào tạo",
    color: "orange",
  },
  {
    id: 2,
    name: "Đã hoàn thành ",
    color: "green",
  },
  {
    id: 3,
    name: "Tạm dừng",
    color: "pink",
  },
  {
    id: 4,
    name: "Bị huỷ",
    color: "red",
  },
];
export const APPROVAL_OPTION = [
  {
    id: 0,
    value: true,
    name: "Đã duyệt",
    color: "green",
  },
  {
    id: 1,
    value: false,
    name: "Chưa duyệt",
    color: "red",
  },
];
export const USING_OPTION = [
  {
    id: 0,
    value: false,
    name: "Đang sử dụng",
    color: "green",
  },
  {
    id: 1,
    value: true,
    name: "Ngừng sử dụng",
    color: "red",
  },
];
export const MA_APPROVAL_OPTION = [
  {
    id: 0,
    value: true,
    name: "Đã chốt",
    color: "green",
  },
  {
    id: 1,
    value: false,
    name: "Chưa chốt",
    color: "red",
  },
];

export const CONTRACT_STATUS_OPTION = [
  {
    id: 0,
    name: "Chưa hiệu lực",
    color: "orange",
  },
  {
    id: 1,
    name: "Đang hiệu lực",
    color: "green",
  },
  {
    id: 2,
    name: "Quá hạn",
    color: "red",
  },
  {
    id: 3,
    name: "Tạm dừng",
    color: "pink",
  },
];

export const GNYC_STATUS = {
  Huy: 0,
  DaXuLy: 1,
  ChoXuLy: 2,
  DangXuLy: 3,
  ChoTiepNhan: 4,
  DaNghiemThu: 5,
  TamHoan: -1,
};
export const GNYC_DOUUTIEN = {
  Thap: 0,
  TrungBinh: 1,
  Gap: 2,
  RatGap: 3,
};

export const GNYC_DOUUTIEN_OPTION = [
  {
    id: GNYC_DOUUTIEN.Thap,
    name: "Thấp",
    color: "gray",
  },
  {
    id: GNYC_DOUUTIEN.TrungBinh,
    name: "Trung bình",
    color: "yellow",
  },
  {
    id: GNYC_DOUUTIEN.Gap,
    name: "Gấp",
    color: "orange",
  },
  {
    id: GNYC_DOUUTIEN.RatGap,
    name: "Rất gấp",
    color: "red",
  },
];

export const GNYC_STATUS_OPTION = [
  {
    id: GNYC_STATUS.ChoTiepNhan,
    name: "Chờ tiếp nhận",
    color: "yellow",
  },
  {
    id: GNYC_STATUS.ChoXuLy,
    name: "Chờ xử lý",
    color: "orange",
  },
  {
    id: GNYC_STATUS.DangXuLy,
    name: "Đang xử lý",
    color: "gray",
  },
  {
    id: GNYC_STATUS.DaXuLy,
    name: "Đã xử lý",
    color: "blue",
  },
  {
    id: GNYC_STATUS.DaNghiemThu,
    name: "Đã nghiệm thu",
    color: "green",
  },
  {
    id: GNYC_STATUS.Huy,
    name: "Huỷ",
    color: "red",
  },
  {
    id: GNYC_STATUS.TamHoan,
    name: "Tạm hoãn",
    color: "pink",
  },
];

export const DOCUMENT_TYPE_ID = {
  DonXinNghi: 1,
  PhieuTangCa: 2,
  PhieuDaoTao: 3,
  DeXuatTuyenDung: 4,
  HopDongLaoDong: 5,
  VanBanNoiBoDi: 6,
  PhieuQuyChe: 7,
  PhieuVanBan: 8,
  PhieuCom: 9,
  QuanLyDuAn: 10,
  DanhGiaUngVien: 11,
  HoSoTaiLieu: 12,
  DanhGiaThuViec: 13,
  HoSoUngVien: 14,
  QuanLyCongViec: 15,
  QuanLyCongVanBenNgoaiDi: 16,
  QuanLyCongVanBenNgoaiDen: 17,
  KskPhuongAnKinhDoanh: 18,
  KskThanhLyHopDong: 19,
  DeXuatMuaSam: 20,
  NhapHang: 21,
  XuatHang: 22,
  DonHangKsk: 23,
  LuanChuyen: 24,
  BanLe: 25,
  BanBuon: 26,
  DieuChuyenNhanVien: 27,
};

export const DOCUMENT_TYPE_COMP = Object.entries(DOCUMENT_TYPE_ID).map(
  ([key, value]) => ({
    id: value,
    comp: key,
  })
);

// export const KIEU_VAN_BAN = [
//   { label: "Nội bộ", value: 1 },
//   { label: "Nơi khác chuyển đến", value: 2 },
// ];
export const HINHTHUC_CONG_VAN = {
  NoiBo: 1,
  BenNgoai: 2,
};
export const KIEU_CONG_VAN = {
  Den: 1,
  Di: 2,
};
export const SIGNATURE_STATUS = {
  Unsigned: 0,
  Signed: 1,
  Declined: 2,
};

export const TIEN_TRINH = [
  { label: "Chờ tiếp nhận", value: 1, mamau: "#f50" },
  { label: "Đang tiếp nhận", value: 2, mamau: "#2db7f5" },
  { label: "Đã tiếp nhận", value: 3, mamau: "#87d068" },
];

export const SIGN_VALUE = {
  Unsigned: 0,
  Signed: 1,
  Declined: 2,
};
export const SIGN_STATUS = [
  {
    name: "Tất cả",
    color: "gray",
  },
  {
    id: SIGN_VALUE.Unsigned,
    name: "Chưa ký",
    color: "orange",
  },
  {
    id: SIGN_VALUE.Signed,
    name: "Đã ký",
    color: "green",
  },
  {
    id: SIGN_VALUE.Declined,
    name: "Quá hạn",
    color: "red",
  },
];
export const SIGN_OPTION_STATUS = [
  {
    name: "Tất cả",
    value: 0,
  },
  {
    name: "Chờ ký",
    value: 1,
  },
  {
    name: "Chờ bạn ký",
    value: 2,
  },
  {
    name: "Bạn đã ký",
    value: 3,
  },
  {
    name: "Bị từ chối",
    value: 4,
  },
  {
    name: "Hoàn thành",
    value: 5,
  },
];

export const TRANG_THAI_KY = [
  { label: "Đã hoàn thành", value: 1, mamau: "#f50" },
  { label: "Đang tiếp nhận", value: 2, mamau: "#2db7f5" },
  { label: "Đang cập nhật", value: 0, mamau: "#87d068" },
];

export const EProjectDocumentType = {
  None: 0,
  QuyChe: 1,
  VanBan: 2,
  HoSo: 3,
  NhomQuyChe: 4,
};

export const DEXUAT_STATUS_OPTIONS = [
  { id: 0, color: "red", name: "Chưa duyệt" },
  { id: 1, color: "gray", name: "Chờ duyệt" },
  { id: 2, color: "yellow", name: "Đã duyệt" },
  { id: 3, color: "blue", name: "Đang tuyển" },
  { id: 4, color: "green", name: "Hoàn thành" },
  { id: 5, color: "purple", name: "Hoàn thành" },
];
export const HOSOUNGVIEN_STT_OPTIONS = [
  { id: 0, color: "gray", name: "Ứng tuyển" },
  { id: 1, color: "gray", name: "Ứng tuyển" },
  { id: 2, color: "yellow", name: "Phỏng vấn" },
  { id: 3, color: "red", name: "Không trúng tuyển" },
  { id: 4, color: "green", name: "Trúng tuyển" },
];
export const DANHGIAUNGVIEN_STT_OPTIONS = [
  { id: 0, color: "blue", name: "Chờ đánh giá" },
  { id: 2, color: "yellow", name: "Chờ xét duyệt" },
  { id: 3, color: "red", name: "Không trúng tuyển" },
  { id: 4, color: "green", name: "Trúng tuyển" },
];

export const DANHGIATHUVIEC_STT_OPTIONS = [
  { id: 0, color: "blue", name: "Đang thử việc" },
  { id: 1, color: "gray", name: "Nghỉ việc" },
  { id: 2, color: "green", name: "Chính thức" },
];
export const TRANG_THAI_UU_TIEN = [
  { id: 1, color: "green", name: "Bình thường" },
  { id: 2, color: "red", name: "Gấp" },
];

export const TONGHOPMUASAM_STT_OPTIONS = [
  { id: 0, color: "red", name: "Chờ xử lý" },
  { id: 1, color: "blue", name: "Đang xử lý" },
  { id: 2, color: "green", name: "Đã duyệt" },
  { id: 3, color: "green", name: "Đã nghiệm thu" },
  { id: 4, color: "orange", name: "Tạm hoãn" },
  { id: 5, color: "red", name: "Hủy" },
];

export const ERP_NOTIFICATION_TYPE = [
  {
    name: "Tất cả",
    value: 0,
  },
  {
    name: "Hệ thống",
    value: 1,
  },
  {
    name: "Phiếu ký",
    value: 2,
  },
  {
    name: "Nhân sự",
    value: 3,
  },
  {
    name: "Khách hàng",
    value: 4,
  },
  {
    name: "Công văn",
    value: 5,
  },
  {
    name: "Công việc",
    value: 6,
  },
];
